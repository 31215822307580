import { Box, Fade } from "@chakra-ui/react";
import Link from "next/link";
import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { FiChevronRight } from "react-icons/fi";
import { generatePath } from "react-router";

import { P } from "@components/atoms/Text/P";
import { paths } from "@paths";

import { IItem, IProps } from "./types";

const getItemPath = (item: IItem) => {
  const { url, category, collection, page } = item;
  if (url) return url;

  if (category) {
    return generatePath(paths.category, { slug: category.slug });
  }
  if (collection) {
    return generatePath(paths.collection, { slug: collection.slug });
  }
  if (page) {
    return generatePath(paths.page, { slug: page.slug });
  }
};

export const MobileNavMainMenuItem: React.FC<IProps> = ({
  item,
  setActiveMenu,
  isParent,
  hideNav,
  textTransform = "uppercase",
  padding = "0rem 1.25rem",
}) => {
  const { name, children, legacyUrl } = item;
  const [activeItem, setActiveItem] = useState();

  const path = legacyUrl || getItemPath(item);
  const isLinkItem = path && !children?.length;

  const handleClick = () => {
    setActiveMenu?.(item);
  };

  const isDesignersPage =
    path === "/designers" || name.toLowerCase().includes("designers");

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        textAlign="left"
        color="black"
        p={padding}
        onClick={() => hideNav?.()}
      >
        <P
          fontSize="1.2rem"
          textTransform={textTransform}
          justifyContent="space-between"
          display="flex"
        >
          {isLinkItem ? (
            isDesignersPage ? (
              <a href="/designers">{name}</a>
            ) : (
              <Link passHref href={path}>
                <a>{name}</a>
              </Link>
            )
          ) : (
            <Box w="100%">
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  if (isParent) {
                    handleClick();
                  } else if (item.children && item.children.length > 0) {
                    setActiveItem((_activeItem) => (_activeItem ? null : item));
                  }
                }}
                display="flex"
                w="100%"
                justifyContent="space-between"
              >
                <Box>{name}</Box>
                <Box w="0.8rem" cursor="pointer" my="auto">
                  {isParent ? (
                    <FiChevronRight size={24} />
                  ) : activeItem ? (
                    <AiOutlineMinus size={16} />
                  ) : item.children && item.children.length > 0 ? (
                    <AiOutlinePlus size={16} />
                  ) : null}
                </Box>
              </Box>

              {activeItem && (
                <Fade
                  in={!!activeItem}
                  transition={{
                    enter: { duration: 0.5 },
                    exit: { duration: 1 },
                  }}
                >
                  <Box as="ul" ml="2rem" mt="1rem">
                    {activeItem.children?.map((_item) => (
                      <MobileNavMainMenuItem
                        item={_item}
                        textTransform="none"
                        padding={0}
                      />
                    ))}
                    {item.url && item.name && (
                      <a href={item.url} target={item?.target ?? "_self"}>
                        <span
                          style={{
                            textTransform: "none",
                            color: "rgb(65,88,255)",
                            fontSize: "16px",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          Browse all {item.name.toLowerCase()}
                        </span>
                      </a>
                    )}
                  </Box>
                </Fade>
              )}
            </Box>
          )}
        </P>
      </Box>
    </>
  );
};
