import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";

import { getLinkMenuItem } from "@app/core/utils";
import { CategoryImageMainMenu } from "@components/molecules/MainMenuExpandableSection/CategoryImage";
import { MenuItem } from "@graphql/gqlTypes/MenuItem";

import { MainMenuExpandableSectionProps } from "./types";

interface SubHeaderProps {
  menuItem?: MenuItem[] | null;
  index: number;
}

interface ChildMenuProps {
  menuItems?: MenuItem[] | null;
  index: number;
}

export const MainMenuExpandableSection = ({
  mainMenu,
  lower,
}: MainMenuExpandableSectionProps) => {
  if (!mainMenu) return null;

  const [hoveredIndex, setHoveredIndex] = useState<Number>(-1);
  const prevHoveredIndexRef = useRef<Number>(-1);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    prevHoveredIndexRef.current = hoveredIndex;
  }, [hoveredIndex]);

  const handleMouseEnter = (index: number) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredIndex(-1);
    }, 100);
  };

  return (
    <nav>
      <Box display="flex" flexDirection="row">
        {mainMenu?.items?.map((item, index) => {
          const getUrl = getLinkMenuItem(item);

          const isDesignersPage = getUrl === "/designers";

          if (item)
            return (
              <Box
                paddingY="1rem"
                paddingX="0.75rem"
                position="relative"
                key={item?.id}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave()}
              >
                {isDesignersPage ? (
                  <a href={getUrl}>{item?.name.toUpperCase()}</a>
                ) : (
                  <Link href={getUrl} passHref>
                    <a target={item?.target || undefined}>
                      {item?.name.toUpperCase()}
                    </a>
                  </Link>
                )}

                <Box
                  position="absolute"
                  left={0}
                  top="100%"
                  width="100%"
                  opacity={hoveredIndex === index ? 100 : 0}
                  height="auto"
                  background="white"
                  paddingY="1rem"
                  display="flex"
                  visibility={hoveredIndex === index ? "visible" : "hidden"}
                  transition={
                    hoveredIndex === -1 || prevHoveredIndexRef.current === -1
                      ? "opacity 0.2s ease-in-out, visibility 0.2s ease-in-out"
                      : "none"
                  }
                >
                  <Box
                    position="fixed"
                    left={0}
                    top={lower ? "124px" : "72px"}
                    width="100%"
                    background="#f2f2f2"
                    paddingY="2.5rem"
                  >
                    <Box paddingX="6rem">
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="start"
                        gap="2rem"
                      >
                        <Box width="25%">
                          <Box
                            display="flex"
                            flexDirection="column"
                            lineHeight="1.7rem"
                          >
                            <Box
                              as="h2"
                              fontSize="2.5rem"
                              lineHeight="2.7rem"
                              margin="0"
                            >
                              Rent {item?.name}
                            </Box>
                            <Box
                              textColor="#2563eb"
                              marginTop={1}
                              _hover={{
                                "span:hover": {
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {isDesignersPage ? (
                                <a href={getUrl}>
                                  <span>
                                    Browse all {item?.name?.toLowerCase()}
                                  </span>
                                </a>
                              ) : (
                                <Link href={getUrl} passHref>
                                  <a target={item?.target || undefined}>
                                    <span>
                                      Browse all {item?.name?.toLowerCase()}
                                    </span>
                                  </a>
                                </Link>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        <Box width="60%">
                          <SubMenu menuItem={item.children} index={index} />
                        </Box>
                        <Flex h="full" direction="column" width="25%">
                          <CategoryImageMainMenu
                            nativeAnchor={isDesignersPage}
                            name={item?.name}
                            subMenu={item}
                            getUrl={getUrl}
                          />
                        </Flex>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
        })}
      </Box>
    </nav>
  );
};

const SubMenu = ({ menuItem, index }: SubHeaderProps) => {
  if (!menuItem) {
    return null;
  }

  return (
    <Grid templateColumns="repeat(3, minmax(0, 1fr))" gap="2rem">
      {menuItem &&
        menuItem.map((submenuItem, submenuIndex) => {
          const getUrl = getLinkMenuItem(submenuItem);
          const isDesignersPage = getUrl === "/designers";

          return (
            <GridItem>
              <Box key={`${index}-${submenuIndex}`}>
                <Box
                  as="span"
                  display="flex"
                  alignItems="start"
                  fontWeight="light"
                >
                  <Box display="flex" flexDirection="column">
                    {getUrl ? (
                      <Box
                        as="span"
                        marginBottom="1rem"
                        flex="1 1 0%"
                        fontSize="1.5rem"
                        lineHeight="1.7rem"
                      >
                        {isDesignersPage ? (
                          <a href={getUrl}>
                            {submenuItem?.name?.toUpperCase()}
                          </a>
                        ) : (
                          <Link href={getUrl} passHref>
                            <a target={submenuItem?.target || undefined}>
                              {submenuItem?.name?.toUpperCase()}
                            </a>
                          </Link>
                        )}
                      </Box>
                    ) : (
                      <Box
                        as="span"
                        marginBottom="1rem"
                        flex="1 1 0%"
                        fontSize="1.5rem"
                      >
                        {submenuItem?.name?.toUpperCase()}
                      </Box>
                    )}

                    {submenuItem.children && submenuItem.children.length ? (
                      <ChildMenu
                        menuItems={submenuItem.children}
                        index={index}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </GridItem>
          );
        })}
    </Grid>
  );
};

const ChildMenu = ({ menuItems, index }: ChildMenuProps) => {
  if (!menuItems) {
    return null;
  }

  return menuItems.map((childItem, childIndex) => {
    const getUrl = getLinkMenuItem(childItem);
    const isDesignersPage = getUrl === "/designers";

    return (
      <Box
        marginY="0.2rem"
        display="flex"
        justifyItems="center"
        alignItems="center"
        _hover={{
          "a:hover": {
            textColor: "#9a9a9a",
          },
        }}
        fontSize="1rem"
      >
        {isDesignersPage ? (
          <a href={getUrl}>{childItem?.name}</a>
        ) : (
          <Link href={getUrl} key={`${index}-${childIndex}`} passHref>
            <a target={childItem?.target || undefined}>{childItem?.name}</a>
          </Link>
        )}
      </Box>
    );
  });
};
