import { Text } from "@chakra-ui/react";
import { FormattedMessage } from "@react-intl";
import { ProductDetails_attributes } from "@the-volte/svc-core-sdk/lib/fragments/gqlTypes/ProductDetails";
import Link from "next/link";
import React from "react";

import { RichTextEditorContent } from "@components/atoms";
import { Money } from "@components/containers";
import useItemCondition from "@hooks/useItemCondition";
import { legacyCategories } from "@utils/menu";

import * as S from "./styles";
import { IProps } from "./types";

const getAttributeValueElement = (
  attribute: ProductDetails_attributes,
  categorySlug: string
) => {
  if (!attribute.values?.length || !attribute?.values?.[0]?.name) {
    return <>&nbsp;</>;
  }
  const firstValue = attribute.values[0];
  if (attribute.attribute.slug === "designer" && firstValue) {
    if (!firstValue.slug) return;

    return (
      <Link
        href={`/designers/${
          firstValue.slug.endsWith("-hire")
            ? firstValue.slug
            : firstValue.slug.concat("-hire")
        }`}
        prefetch={false}
      >
        <a>{attribute.values[0].name}</a>
      </Link>
    );
  }

  if (attribute.attribute.slug === "item-style") {
    return (
      <>
        {attribute.values
          .filter((value) => value?.slug)
          .map((value, index) => (
            <React.Fragment key={index}>
              {index > 0 && ", "}
              <Link href={`/occasions/${value?.slug}`} prefetch={false}>
                {value?.name}
              </Link>
            </React.Fragment>
          ))}
      </>
    );
  }

  let categoryLinkPrefix = `/category`;
  if (legacyCategories.includes(categorySlug)) {
    categoryLinkPrefix = "";
  }

  return (
    <>
      {attribute.values
        .filter((value) => value?.slug)
        .map((value, index) => (
          <Link
            key={`${value?.id} || ${index}`}
            href={`${categoryLinkPrefix}/${categorySlug}/?filters=${attribute.attribute.slug}_${value?.slug}`}
            prefetch={false}
          >
            {value?.name}
          </Link>
        ))
        .map((element, index, array) => (
          <React.Fragment key={index}>
            {element}
            {index < array.length - 1 && ", "}
          </React.Fragment>
        ))}
    </>
  );
};

export const ProductDescription: React.FC<IProps> = ({
  description,
  categorySlug,
  product,
  attributes,
}: IProps) => {
  const { itemCondition, itemConditionDisplayValue } = useItemCondition({
    attributes,
  });

  return (
    <S.Wrapper>
      <S.Section>
        <S.SectionTitle>
          <FormattedMessage defaultMessage="Location" />
        </S.SectionTitle>
        <Text>{product?.location}</Text>
      </S.Section>
      <S.Section>
        <S.SectionTitle>
          <FormattedMessage defaultMessage="Description" />
        </S.SectionTitle>
        <RichTextEditorContent jsonData={description} />
      </S.Section>

      <S.Section>
        <S.SectionTitle>
          <FormattedMessage defaultMessage="Details" />
        </S.SectionTitle>
        {itemCondition && itemCondition.values.length > 0 && (
          <S.AttributeList>
            <li>
              <S.AttributeName>{itemCondition.attribute.name}</S.AttributeName>
            </li>
            <li>
              {itemCondition.values.map((value, index) => (
                <React.Fragment key={`${value?.id}_${index}`}>
                  {itemConditionDisplayValue(value)}
                </React.Fragment>
              ))}
            </li>
          </S.AttributeList>
        )}
        <S.AttributeList isShippingMethodsList>
          <li style={{ paddingTop: "2px" }}>
            <S.AttributeName>Shipping Methods:</S.AttributeName>
          </li>
          <li>
            {product?.shippingMethods
              ?.sort((_, b) => (b?.name?.includes("International") ? -1 : 1))
              ?.map((sm, index) => (
                <p key={`${sm?.id} || ${index}`}>
                  {sm?.name}{" "}
                  {sm?.price?.amount === 0 ? (
                    `(Free)`
                  ) : (
                    <Money money={sm?.price} showDecimals />
                  )}
                </p>
              ))}
          </li>
        </S.AttributeList>
        <S.AttributeList>
          {attributes && (
            <>
              {attributes
                .filter((attr) => attr.attribute.slug !== "condition")
                .map((attribute, index) => (
                  <React.Fragment key={index}>
                    {attribute.values.length !== 0 && (
                      <>
                        <li key={`label_${index}`}>
                          <S.AttributeName>
                            {attribute.attribute.name}:
                          </S.AttributeName>
                        </li>
                        <li key={`attr_${index}`}>
                          {getAttributeValueElement(
                            attribute,
                            categorySlug || ""
                          )}
                        </li>
                      </>
                    )}
                  </React.Fragment>
                ))}
            </>
          )}
          <li>
            <S.AttributeName>Retail Value:</S.AttributeName>
          </li>
          <li>
            {product.retailValue && (
              <Money money={product.retailValue} showDecimals={false} />
            )}
          </li>
          {product.sizeFitNotes !== null && (
            <>
              <li>
                <S.AttributeName>Size & Fit Notes:</S.AttributeName>
              </li>
              <li>{product.sizeFitNotes}</li>
            </>
          )}
        </S.AttributeList>
      </S.Section>
    </S.Wrapper>
  );
};
